import React from 'react'
import Page from '../components/Homemade/RatingsPage/RatingsPage'
import generatePagePropsOnBuildTime from '../helpers/generatePagePropsOnBuildTime'

const Ratings = (props) => {
    return <Page {...generatePagePropsOnBuildTime(props, {
        data: {
            products: []
        }
    })} />
}

export default Ratings